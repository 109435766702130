<style scoped>
.login {
  background-color: whitesmoke;
  /*position: fixed;*/
}

.img-background {
  /*background-image: url("../assets/h5login/bg_h5login.png");*/
  height: 100vh;
  width: 100vw;
  background-size: 120% 120%;
}

.login-form {
  /*background-image: url("../assets/login/bg.png");*/
  background-position: center;
  height: 50vh;
  width: 90vw;
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -45%);
  text-align: center;
}
.login-form-log {
    margin-top: -90px;
    height: 90px;
}
.login-form > .login_title{
  text-align: center;

  margin-top: 25px;
  line-height: 36px;
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  height: 23.9vw;
  width: 75.6vw;
  margin-left: 7.2vw;
  background: #F6F6F6;
  border-radius: 16px;
  margin-bottom: 44px;
  padding-top: 8px;
}

.login-form > .login_title > img{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: block;
  margin-left:  33vw;




}
.login-form-user {
  background-color: #F6F6F6;
  text-align: left;
  height: 72px;
  border-radius: 16px;
  /*background: url("../assets/h5login/input_bg.png") no-repeat;*/
  margin-bottom: 20px;
  /*margin-top: 44px;*/
  background-size: 100% 100%;
}
.login-form > p {
  display: block;
  font-size: 12pt;
  color: #222222;
  font-family: PingFangSC-Regular;
  /*letter-spacing: 2px;*/
  margin-left: 5%;
  text-align: start;
}

.login-form-user > el-input:focus {
  outline: none;
}

.login-form-user > img{

}
.login-button {
  width: 74.6vw;
  height: 44px;
  background: #F1BD20;
  color: white;
  box-shadow: 0px 6px 27px 0px rgba(241, 189, 32, 0.27);
  border-radius: 5.8vw;
  font-size: 18px;
  margin-top: 33.4vh;
  /*margin: 32px 30px 0 12.7vw;*/

}
/deep/.el-input__inner {
    background-color: transparent;
    border: none;

  }

.error {
  line-height: 40px;
  font-size: 15px;
  position: absolute;
  top: 72%;
  left: 44.7%;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  color: red;
}
.rt{
  margin-top: 8px;
  text-align: left;
  margin-left: 5%;
}



</style>



<template>
  <div class="login" v-loading.fullscreen.lock="fullscreenLoading">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">

          <div class="login-form">
              <div class="login-form-log">
<!--                <img height="44" src="../assets/h5login/logo_h5.png" width="216"/>-->
              </div>

            <p class="login_title">
              <img :src="merLogo"/>
              {{merchantName}}</p>


            <el-form-item class="login-form-user"  prop="userphone">

              <img style="height: 14px;margin-left: 16px;margin-bottom: 3px;" src="@/assets/image/pay/icon_amount.png"/>
              <p style="display: inline;margin-left: 5px;font-weight: bold;line-height: 72px;">订单金额：</p>
              <el-input class="user_input" style="width: 50vw;" id="userphone" v-model="ruleForm.userphone"  autocomplete="on" placeholder="请输入订单金额" type="number" clearable/>
            </el-form-item>
            <el-form-item class="login-form-user"  prop="password">

              <img style="height: 14px;margin-left: 16px;margin-bottom: 3px;" src="@/assets/image/pay/icon_remark.png"/>
              <p style="display: inline;margin-left: 5px;font-weight: bold;line-height: 72px;">附言：&emsp;&emsp;</p>
              <el-input class="user_input" style="width: 50vw;" id="userphone" v-model="ruleForm.password"  autocomplete="on" placeholder="请输入附言" type="text" clearable/>
            </el-form-item>
<!--            <div class="rt">-->
<!--              <el-checkbox style="margin-left: 10px;" v-model="checked" >记住密码</el-checkbox>-->
<!--            </div>-->
            <el-button class="login-button" @click="submitForm('ruleForm')">确 定</el-button>

            <div v-if="isShow" class="error">{{ err_message }}</div>
            <div style="font-size: 10px;margin-top: 12px;color: #F1BD20;">温馨提醒：请您在付款前仔细确认交易对象和交易金额</div>
          </div>
              </el-form>



    </div>
</template>


<script>

// import {request} from "@/network/network";
// import { ElMessage } from 'element-plus'

export default {
  name: "pay",
  data() {
    return {
      userphone: null,
      password: null,
      isShow: false,
      err_message: null,
      merLogo:require("@/assets/image/pay/icon_merchant.png"),
      fullscreenLoading:false,
      merchantName: "",
      checked: false,
        ruleForm: {
          userphone: '',
          password:''
        },
        rules: {
          userphone: [
            { required: true, message: '请输入金额', trigger: 'blur' },
           {
              validator:function(rule, value, callback){
                let regPos = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/;
                if(regPos.test(value) == false){
                  callback(new Error("金额格式错误"))
                }else{
                  callback()
                }
              },
              trigger:'blur'
            }
          ],
          password: [
            // { required: true, message: '请输入密码', trigger: 'blur' },
            // { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
          ]
        },

    }
  },
  methods: {
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
         if (valid) {
            this.login()//调用登录方法
          }
         else {
            return false;
          }
        });
      },
    login() {
        //
      let _this = this

      // console.log(this.$route.query)
      let merId = this.$route.query.merchantId;
      // console.log(merId)
      if (merId == undefined){
          _this.$message.error("缺少请求参数!")
      }
      let params = {
        "amount": this.ruleForm.userphone,
        "desc": this.ruleForm.password,
        "merchantId": merId
      }
      // console.log(params)
      _this.fullscreenLoading = true
      Http.post('frontend/merchant/pay', params).then(result => {
        if (result && result.code === 200) {
          // console.log(result)
          let uri = result.data.oneQRForAll;
          if(uri && uri.length > 0){
            this.$message({
              message: '请求成功,正在跳转…',
              type: 'success'
            });
              window.location.replace(uri)

          }

        } else {
          if (result.message){
            _this.$message.error(result.message)
          }else{
            _this.$message.error("请求失败")
          }
        }
        _this.fullscreenLoading = false
      }).catch(error => {
        _this.$message.error(error)
        _this.fullscreenLoading = false
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    reset_pwd() {
    },
    getMerchant(){
      let _this = this;
      let merId = this.$route.query.merchantId;
      // alert(merId)
      if (merId == undefined){
          this.$message.error("缺少请求参数!")
      }
      Http.get('frontend/merchant/getMerchant?merchantId='+merId).then(result => {
        if (result && result.code === 200) {
          // console.log(result)
          if (result.data.merName){
              _this.merchantName = result.data.merName;
              _this.merLogo = result.data.merLogoUrl;
          }
        }
        else
        {
          if (result.message){
            _this.$message.error(result.message)
          }else{
            _this.$message.error("请求失败")
          }
        }
      }).catch(error => {
        _this.$message.error(error)
      }).finally(() => {
        _this.fullscreenLoading = false
      })

    },

  },
  mounted() {
    this.getMerchant()
  },
  created(){
        document.title="向商户付款"
    }

}
</script>